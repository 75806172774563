import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
// import SearchBar from './SearchBar';
import Listelement from './listElement';
import '../App.css';

function About() {

  useEffect(() => {
    async function fetchData() {

      try {
        const url = "https://backend-gdauto.onrender.com/read"
        const response = await fetch(url, {
          method: 'GET'
        })
        const content = await response.json();
        const elements = content.field;
        console.log(elements);
        setFields(elements);
      } catch (error) {
        console.log(error)
      }

    }

    fetchData();
  }, []);

  const [fieldsTemp, setFieldsTemp] = useState([]);
  const [fields, setFields] = useState([{}]);

  const onSearch = (fields, fieldsTemp) => {
    // alert('cercando....');
    const inValue = document.getElementsByTagName("input")[1]?.value;
    console.log('searching for : ' + inValue);

    if (!fieldsTemp.length) {
      setFieldsTemp(fields);
      fieldsTemp = fields; // per prima volta runtime a functtion
    }

    if (!inValue) {
      setFields(fieldsTemp);
      return;
    }
    const fieldsFiltered = fieldsTemp.filter(car => car.nome.toLowerCase().includes(inValue.toLowerCase()));
    setFields(fieldsFiltered);
  }

  return (
    <>
      <Navbar current="About"></Navbar>
      <section id="showcaseAbout">
        <div className="container">
          <h1>LISTA <span className="text-color-primary">VEICOLI</span></h1>
          <div className="searchBar">
            <input type="text" placeholder="Search.." />
            {
              <button className='btn-black' onClick={() => { onSearch(fields, fieldsTemp) }} ><i className="fa fa-search fa-2x" aria-hidden="true"></i></button>
            }

          </div>
          {fields.map((field, index) => (
            !field.Noleggio ? <Listelement element={field} key={index}></Listelement> : null
            
          ))}

        </div>
      </section>
    </>
  );
}

export default About;
