import React from 'react';
// import logo from '../img-carosello/gdauto_logo.png'
import { Link } from 'react-router-dom';

const Navbar = (props) => {


    let admin = true;
    let hiddenAdm = "";
    // let hiddenOut = "";
    // let hiddenIn = "";
    const getRole = localStorage.getItem("admin");
    if (getRole == null) {
        admin = false
    }
    if (admin) {
        hiddenAdm = "";
        // hiddenOut = "";
        // hiddenIn = "hidden";
    } else if (!admin) {
        hiddenAdm = "hidden !important";
        // hiddenOut = "hidden !important";
        // hiddenIn = "";
    }

    var Home = "Link";
    var About = "Link";
    var Noleggio = "Link";
    var Info = "Link";
    // var Logout = "Link";
    var Admin = "Link";
    // var Login = "Link";
    var Sell = "Link";

    if (props.current === 'Home') {
        Home = "current"
    }
    if (props.current === 'About') {
        About = "current"
    }
    if (props.current === 'Info') {
        Info = "current"
    }
    // if (props.current === 'Logout') {
    //     Logout = "current"
    // }
    if (props.current === 'Admin') {
        Admin = "current"
    }
    // if (props.current === 'Login') {
    //     Login = "current"
    // }
    if (props.current === 'Sell') {
        Sell = "current"
    }
    if (props.current === 'Noleggio') {
        Noleggio = "current"
    }
    // const clearToken = () => {
    //     localStorage.removeItem("admin");
    // }

    return (
        <header>
            <nav id="Navbar">
                <div className="container">
                    <h1><span className="text-color-primary">GDAUTO</span></h1>
                    {/* <img className="Logo" alt='' src={logo} ></img> */}
                    <input type="checkbox" id="check"></input>
                    <label htmlFor="check" id="nav-btn">
                        <i className="fas fa-bars fa-2x"></i>
                    </label>
                    <ul id="list-navbar">
                        
                        <li><Link className={Home} to="/">Home</Link></li>
                        <li><Link className={About} to="/About">Vetrina</Link></li>
                        <li><Link className={Noleggio} to="/Noleggio">Noleggio</Link></li>
                        <li><Link className={Sell} to="/Sell">Vendi la tua auto</Link></li>
                        <li><Link className={Info} to="/Info">Contatti</Link></li>
                        <li hidden={hiddenAdm}><Link className={Admin} to="/Admin">Admin</Link></li>
                        {/* <li hidden={hiddenIn}><Link className={Login} to="/Login">Login</Link></li> */}
                        {/* <li hidden={hiddenOut} ><Link className={Logout} onClick={clearToken} to="/Login">Logout</Link></li> */}
                        {/* <li id='li-logo'></li> */}
                        
                    </ul>
                    {/* <img className="Logo" alt='' src={logo} ></img> */}


                </div>
            </nav>
        </header>
    );
}

export default Navbar;