import React from 'react';
import '../App.css';
import Util from '../Util.js';

function Listelementmod(props) {

    var util = new Util();

    const click = () => {

        util.dragElement(document.getElementById("header-dialog"));

        document.getElementById("Dialog").style.display = 'block';
        const ID = props.auto.ID;
        var input = document.getElementById("input-ID-admin");
        var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
        nativeInputValueSetter.call(input, ID);
        var ev = new Event('input', { bubbles: true });
        input.dispatchEvent(ev);

    }
    return (
        <tr id={props.id} onClick={(e) => click(e)}>
            <td className='show-column' >{props.auto.ID}</td>
            <td>{props.auto.nome}</td>
            <td className='show-column' >{props.auto.date}</td>
            <td className='show-column' >{props.auto.type}</td>
            <td className='show-column' >{props.auto.KM}</td>
            <td className='show-column' >{props.auto.price}</td>
            <td className='show-column' >{props.auto.CV}</td>
            <td >{props.auto.fuel}</td>
        </tr>
    );
}

export default Listelementmod;
