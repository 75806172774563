import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Carosello = (props) => {


    useEffect(() => {
        async function fetchData() {

            try {
                const url = "https://backend-gdauto.onrender.com/read"
                debugger
                const response = await fetch(url, {
                    method: 'GET'
                })
                const content = await response.json();
                const elements = content.field;
                console.log(elements);
                setFields(elements);
            } catch (error) {
                console.log(error);
                setFields([{
                    "CV": "125", "ID": 1, "KM": "30000", "date": "", "descrizione": "focust st line del 2021", "fuel": "benzina", "immagine": "", "immagine2": "", "nome": "ford focus",
                    "price": "1M", "type": "berlina"
                }, { "nome": "ford" }, { "nome": "ford" }]);
            }
        }

        fetchData();
    }, []);

    let styleImg = {
        width: 100 + "%",
        height: 100 + "%"
    }

    const [fields, setFields] = useState([{}])


    let carouselArray = fields;
    const [x, setX] = useState(0)
    debugger

    // window.setInterval(function(){
    //     goLeft();
    //   }, 7000);

    const goLeft = () => {
        x === 0 ? setX(-100 * (carouselArray.length - 1)) : setX(x + 100);
    };
    const goRight = () => {
        x === -100 * (carouselArray.length - 1) ? setX(0) : setX(x - 100);
    };
    return (
        <div className="carosello-list">
            {
                carouselArray.map((item, index) => {
                    if (!item.Noleggio && item.Offerta) {
                        return (
                            <div className="slide-list" key={index} style={{ transform: `translateX(${x}%)` }}>
                                <div className="header-slide">
                                    <img src={item.immagine} alt="slide-img" style={styleImg}></img>
                                </div>
                                <div className="info-slide">
                                    <label><span className="text-color-primary">{item.nome}</span></label>
                                    <label>{item.price}  <i className="fas fa-euro-sign"></i></label>
                                    <div className="info-footer">
                                        <span className='btn-black'><Link to={`/InfoPage?autoID=${item.ID}`}>INFO</Link></span>
                                        <span className='btn-black'><Link to={`/Info`}>CONTATTACI</Link></span>

                                    </div>
                                </div>
                            </div>
                        )
                    }else{
                        return <div></div>
                    }

                })
            }
            <button id="goLeft" onClick={goLeft}><i className="fas fa-chevron-left fa-3x"></i></button>
            <button id="goRight" onClick={goRight}><i className="fas fa-chevron-right fa-3x"></i></button>
        </div>
    )

}

export default Carosello;