import React, { useState } from 'react'
import Imgcomp from "./imgComp";

const Caroselloinfo = (props) => {

    console.log("FIELDS VIA AUOTO ID" + props.field)
    let carouselArray = [];
    if (props.field) {
        carouselArray = [<Imgcomp src={props.field.immagine}></Imgcomp>, <Imgcomp src={props.field.immagine2}></Imgcomp>];
        if (props.field.immagine3) {
            carouselArray.push(<Imgcomp src={props.field.immagine3}></Imgcomp>)
        }
        if (props.field.immagine4) {
            carouselArray.push(<Imgcomp src={props.field.immagine4}></Imgcomp>)
        }
        if (props.field.immagine5) {
            carouselArray.push(<Imgcomp src={props.field.immagine5}></Imgcomp>)
        }
        if (props.field.immagine6) {
            carouselArray.push(<Imgcomp src={props.field.immagine6}></Imgcomp>)
        }
        if (props.field.immagine7) {
            carouselArray.push(<Imgcomp src={props.field.immagine7}></Imgcomp>)
        }
        if (props.field.immagine8) {
            carouselArray.push(<Imgcomp src={props.field.immagine8}></Imgcomp>)
        }
        if (props.field.immagine9) {
            carouselArray.push(<Imgcomp src={props.field.immagine9}></Imgcomp>)
        }
        if (props.field.immagine10) {
            carouselArray.push(<Imgcomp src={props.field.immagine10}></Imgcomp>)
        }
        if (props.field.immagine11) {
            carouselArray.push(<Imgcomp src={props.field.immagine11}></Imgcomp>)
        }
        if (props.field.immagine12) {
            carouselArray.push(<Imgcomp src={props.field.immagine12}></Imgcomp>)
        }
        if (props.field.immagine13) {
            carouselArray.push(<Imgcomp src={props.field.immagine13}></Imgcomp>)
        }
        if (props.field.immagine14) {
            carouselArray.push(<Imgcomp src={props.field.immagine14}></Imgcomp>)
        }
        if (props.field.immagine15) {
            carouselArray.push(<Imgcomp src={props.field.immagine15}></Imgcomp>)
        }
    }

    for (var i = 0; i < carouselArray.length; i++) {
        if (carouselArray[i].props.src == null) {
            carouselArray.splice(i, 1);
        }
    }

    const [x, setX] = useState(0)

    const goLeft = () => {
        x === 0 ? setX(-100 * (carouselArray.length - 1)) : setX(x + 100);
    };
    const goRight = () => {
        x === -100 * (carouselArray.length - 1) ? setX(0) : setX(x - 100);
    };
    return (
        <div className="carosello">
            {

                carouselArray.map((item, index) => {
                    return (
                        <div className="slide" key={index} style={{ transform: `translateX(${x}%)` }}>
                            {item}
                        </div>
                    )
                })
            }
            <button id="goLeft" onClick={goLeft}><i className="fas fa-chevron-left fa-3x"></i></button>
            <button id="goRight" onClick={goRight}><i className="fas fa-chevron-right fa-3x"></i></button>
        </div>
    )

}

export default Caroselloinfo;