import React from 'react';

const Imgcomp = ({ src }) => {

    let styleImg = {
        width: 100+"%",
        height: 100+"%"
    }
    return (
        <img src={src} alt="slide-img" style={styleImg}></img>
    );
}

export default Imgcomp;