import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

function listElement(props) {

    return (
        <div className="element">
            <div className="div-immagine-auto">
                {/* <img src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAoHCBUSEhgSEhYYEhgYEhgYGBgYGBgYGBIYGBgZGRgaGBgcIS4lHB4rIRgaJjgmKy8xNTU1HCQ7QDszPy40NTEBDAwMEA8QHxISHzorJCs2NDQ0MTQ/ND03NDE2NDQ0PTY0MT86NDQ0MTQ3NDQ3NDQxNDQ2ND00NDQ0NDQ0NDQ0NP/AABEIAKgBLAMBIgACEQE" alt="immagine"></img> */}
                <img src={props.element.immagine} alt="immagine"></img>
            </div>
            <div className="div-info-auto">
                <h1> <span className="text-color-primary">{props.element.nome}</span></h1>
                <hr></hr>
                <div className="div-description">
                    <div className="div-info-auto-col">
                        <div>
                            <i className="fas fa-gas-pump fa-2x"></i>
                            <label>{props.element.fuel}</label>
                        </div>
                        <div>
                            <i className="fas fa-bolt fa-2x"></i>
                            <label>{props.element.CV} CV</label>
                        </div>
                    </div>

                    <div className="div-info-auto-col">

                        <div>
                            <i className="fab fa-cloudscale fa-2x"></i>
                            <label>{props.element.KM} KM</label>
                        </div>
                        <div>
                            <i className="fas fa-car fa-2x"></i>
                            <label>{props.element.type}</label>
                        </div>
                    </div>

                    <div className="div-info-auto-col">
                        <div>
                            <i className="fas fa-euro-sign fa-2x"></i>
                            <label>{props.element.price}</label>
                        </div>
                        <div>
                            <i className="fas fa-calendar-alt fa-2x"></i>
                            <label>{props.element.date}</label>
                        </div>
                    </div>
                </div>
                <br></br>
                <hr></hr>
                <div className="div-btn">
                
                    {/* <span className="btn-orange" onClick={goInfoPage}><Link className={Info} to="/Info">GO TO OFFER</Link> <i className="fas fa-arrow-right fa-1x"></i></span> */}
                    <span className="btn-black"><Link to={`/InfoPage?autoID=${props.element.ID}`}>INFO</Link></span>
                </div>


            </div>

        </div>
    );
}

export default listElement;
