import React from 'react'
import { Link } from 'react-router-dom'

const Adminnav = (props) => {
    debugger
    const setPageMod = () =>{
        console.log(props.imp)
        document.getElementById(props.imp).style.display = 'none';
        document.getElementById(props.mod).style.display = 'block';
        // debugger
    }

    const setPageImp = () =>{
        console.log(props.imp)
        document.getElementById(props.imp).style.display = 'block';
        document.getElementById(props.mod).style.display = 'none';
        // debugger
    }
    var Import = "Link-adm";
    var Modify = "Link-adm";
  

    if (props.current === 'Import') {
        Import = "current"
    }
    if (props.current === 'Modify') {
        Modify = "current"
    }
    return (
        <header>
            <nav id="Navbar-admin">
                <div className="container">
                    <ul>
                        <li><Link to="/Admin" className={Import} onClick={setPageImp}>IMPORT</Link></li>
                        <li><Link to="/Admin" className={Modify} onClick={setPageMod}>MODIFY</Link></li>
                    </ul>
                </div>
            </nav>
        </header>
    );
}

export default Adminnav;