import React, { useState, useEffect } from 'react';
import '../App.css';
import Listelementmod from './ListElementAdmMod';
import Dialogadm from './Dialogadm';


function Adminmod() {

  useEffect(() => {

    async function fetchData() {
      try {
        const url = "https://backend-gdauto.onrender.com/read"
        const response = await fetch(url, {
          method: 'GET'
        })
        const content = await response.json();
        debugger
        setFields(content.field);
      } catch (error) {
        console.log(error)
      }

    }
    fetchData();
  }, []);

  const [fields, setFields] = useState([{}]);

  return (
    <>
      <section id="showcaseAdminMod" hidden="hidden" >
        <div className="container" >
          <div className="inside-container" >
            <h1>EDIT YOUR <span className="text-color-primary">DATABASE</span></h1>
            <table className="content-table">
              <thead>
                <tr>
                  <th className='show-column' >ID</th>
                  <th>MODEL</th>
                  <th className='show-column'>DATE</th>
                  <th className='show-column'>TYPE</th>
                  <th className='show-column'>KM</th>
                  <th className='show-column'>PRICE</th>
                  <th className='show-column'>CV</th>
                  <th>FUEL</th>
                </tr>
              </thead>
              <tbody>
                {
                  fields.map((field, index) => (
                    <Listelementmod auto={field} id={index}></Listelementmod>
                  ))
                }
              </tbody>
            </table>
            <Dialogadm fields={fields}></Dialogadm>
          </div>
        </div>
      </section>
    </>
  );
}

export default Adminmod;
