import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Navbar from './Navbar'
import '../App.css';

const qs = require('qs');

function Login() {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useHistory();

  const takeCredentials = async event => {
    event.preventDefault();

    const BODY = qs.stringify({
      "username": `${user}`,
      "password": `${password}`
    })
    const url = "https://backend-gdauto.onrender.com/login"

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: BODY
    })
    const content = await response.json();
    if (content.status) {
      setUser('');
      setPassword('');
      if(content.admin){
        localStorage.setItem("admin", "afdsfsdfdfsdftjkl");
      }

      navigate.push("/")
      // const hostname = "http://" + window.location.hostname + ":3000/"
      // window.location.href = hostname;
    } else {
      alert('pwd o username errato')
      setUser('');
      setPassword('');
    }

  }


  const changeUser = (e) => {
    setUser(e.target.value);
  }

  const changePass = (e) => {
    setPassword(e.target.value);
  }

  return (
    <>
     <Navbar current="Login"></Navbar>
      <section id="showcase">
        <div className="container">
          <h1>Please <span className="text-color-primary">Login</span></h1>
          <form id="Login" onSubmit={(e) => takeCredentials(e)}>
            <input type="text" placeholder="username" value={user} onChange={changeUser}></input>
            <input type="password" placeholder="password" value={password} onChange={changePass}></input>
            <button>Login</button>
          </form>
          <Link className="Link" to="/Register"><li>Register</li></Link>
          <Link className="Link" to="/"><li>Enter in web-site like visitor</li></Link>
        </div>
      </section>
    </>
  );

}

export default Login;
