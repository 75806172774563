import React, { useState } from 'react'
import Imgcomp from "./imgComp";

const CaroselloNoleggio = (props) => {

    
    let carouselArray = [];
    
    props.data.forEach(element => {
        debugger
        if (element.Noleggio) {
            carouselArray.push(<Imgcomp src={element?.immagine}></Imgcomp>)
        }
    });
    for(var i = 0; i < carouselArray.length; i++){
        if(carouselArray[i].props.src == null){
            carouselArray.splice(i, 1);
        }
    }
    
    const [x,setX] = useState (0)

    const goLeft = () => {
        x === 0 ? setX(-100*(carouselArray.length-1)) : setX(x + 100);
     };
    const goRight = () => {
        x === -100*(carouselArray.length-1) ? setX( 0 ) : setX(x - 100);
     };
    return (
        <div className="carosello">
            {
                carouselArray.map((item, index) => {
                    return (
                        <div className="slide" key={index} style={{transform:`translateX(${x}%)`}}>
                            {item}
                        </div>
                    )
                })
            }
            <button id="goLeft" onClick={goLeft}><i className="fas fa-chevron-left fa-3x"></i></button>
            <button id="goRight" onClick={goRight}><i className="fas fa-chevron-right fa-3x"></i></button>
        </div>
    )

}

export default CaroselloNoleggio;