import React from 'react';
import Navbar from './Navbar'
import { Link } from 'react-router-dom'
import '../App.css';
import Adminnav from './Adminnav';
import Adminmod from './Adminmod';
import Adminimp from './AdminImp';

function Admin() {
  let role = true;
  const admin = localStorage.getItem("admin");
  if (admin == null) {
    role = false
  }
  
  if (!role) {
    return (
      <>
        <Navbar current="Admin"></Navbar>
        <section id="showcase">
          <div className="container">
            <h1>non sei <span className="text-color-primary">Amministratore</span></h1>
            <Link className="Link" to="/"><li>Login</li></Link>
          </div>
        </section>
      </>
    );
  }


  let Admod = "showcaseAdminMod";
  let Adimp = "showcaseAdminImp";

  return (
    <>
      <Navbar current="Admin"></Navbar>
      <Adminnav imp={Adimp} mod={Admod}></Adminnav>
      <Adminmod></Adminmod>
      <Adminimp></Adminimp>
    </>
  );
}

export default Admin;
