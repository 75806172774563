import React, { useState } from 'react';
import '../App.css';
import Dialogimg from './Dialogimg';
import Util from '../Util.js';
const qs = require('qs');

function Dialogadm() {

    const [i1, setI1] = useState('');
    const [i2, setI2] = useState('');

    /** start handler for dialog draggable */
    var util = new Util();
    /**end handler for dialog draggable */


    const sendData = async () => {
        const divDialog = document.getElementById("Dialogimg");
        let allImg = divDialog.querySelectorAll('img'),
            oBody = {};

        oBody.ID = document.getElementById("input-ID-admin").value;
        oBody.model = document.getElementById("model").value;
        oBody.CV = document.getElementById("CV").value;
        oBody.fuel = document.getElementById("fuel").value;
        oBody.KM = document.getElementById("KM").value;
        oBody.description = document.getElementById("description").value;
        oBody.type = document.getElementById("type").value;
        oBody.price = document.getElementById("price").value;
        oBody.date = document.getElementById("date").value;
        oBody.noleggio = document.getElementById("_checkbox-26").checked ? 1 : 0;
        oBody.offerta = document.getElementById("_checkbox-26_off").checked ? 1 : 0;
        oBody.immagine = allImg[0]?.src || '';
        oBody.immagine2 = allImg[1]?.src || '';
        oBody.immagine3 = allImg[2]?.src || '';
        oBody.immagine4 = allImg[3]?.src || '';
        oBody.immagine5 = allImg[4]?.src || '';
        oBody.immagine6 = allImg[5]?.src || '';
        oBody.immagine7 = allImg[6]?.src || '';
        oBody.immagine8 = allImg[7]?.src || '';
        oBody.immagine9 = allImg[8]?.src || '';
        oBody.immagine10 = allImg[9]?.src || '';
        oBody.immagine11 = allImg[10]?.src || '';
        oBody.immagine12 = allImg[11]?.src || '';
        oBody.immagine13 = allImg[12]?.src || '';
        oBody.immagine14 = allImg[13]?.src || '';
        oBody.immagine15 = allImg[14]?.src || '';

        const BODY = qs.stringify(oBody)
        const url = "https://backend-gdauto.onrender.com/insert/modify"
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: BODY
        })
        const content = await response.json();
        if (content.status) {
            alert("auto modificata")
        }
    }

    async function getData() {
        const id = document.getElementById("input-ID-admin").value;
        const BODY = qs.stringify({ ID: id })
        const url = "https://backend-gdauto.onrender.com/read/autoID"
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: BODY
        })
        const content = await response.json();
        const field = content.field[0];
        document.getElementById("model").value = field.nome;
        document.getElementById("CV").value = field.CV;
        document.getElementById("fuel").value = field.fuel;
        document.getElementById("KM").value = field.KM;
        document.getElementById("description").value = field.descrizione;
        document.getElementById("type").value = field.type;
        document.getElementById("price").value = field.price;
        document.getElementById("date").value = field.date;
        field.Noleggio ? document.getElementById("_checkbox-26").checked = true : document.getElementById("_checkbox-26").checked = false;
        field.Offerta ? document.getElementById("_checkbox-26_off").checked = true : document.getElementById("_checkbox-26_off").checked = false;



        setI1(field.immagine);
        setI2(field.immagine2);

    }

    const deleteItem = async () => {
        const id = document.getElementById("input-ID-admin").value;
        const BODY = qs.stringify({ ID: id })
        const url = "https://backend-gdauto.onrender.com/delete"
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: BODY
        })
        const content = await response.json();
        content.status ? alert("auto eliminata") : alert("error")
    }


    const closeDialog = () => {
        document.getElementById("Dialog").style.display = 'none';

    }

    const openImg = () => {

        const Dialog = document.getElementById("Dialogimg");
        util.dragElement(document.getElementById("div-container"));
        Dialog.show();
    }

    document.onkeydown = function (evt) {
        evt = evt || window.event;
        if (evt.keyCode === 27) {
            document.getElementById("Dialog").style.display = 'none';
            document.getElementById("Dialogimg").close();

        }
    };
    return (
        <div id="Dialog">
            <div id='header-dialog'>
                <h1 id="title-dialog-adm" >EDIT <span className="text-color-primary">DATA</span></h1>
                <div id="div-id-element">
                    <input id="input-ID-admin" hidden="hidden" readOnly onChange={getData}></input>
                </div>
            </div>
            <hr></hr>
            <div className="input-text-col">
                <div>
                    <h3>Model</h3>
                    <input className="inp-text" type="text" id="model" def  ></input>
                </div>
                <div>
                    <h3>Image</h3>
                    <button className="btn-orange" onClick={openImg}><i className="far fa-image fa-2x"></i></button>
                </div>
            </div>

            <div className="input-text-col">
                <div>
                    <h3>Price</h3>
                    <input className="inp-text" type="text" id="price" ></input>
                </div >
                <div>
                    <h3>KM</h3>
                    <input className="inp-text" type="text" id="KM" ></input>
                </div>
            </div>
            <div className="input-text-col">
                <div>
                    <h3>Fuel</h3>
                    <input className="inp-text" type="text" id="fuel" ></input>
                </div>
                <div>
                    <h3>Type</h3>
                    <input className="inp-text" type="text" id="type" ></input>
                </div>
            </div>
            <div className="input-text-col">
                <div>
                    <h3>CV</h3>
                    <input className="inp-text" type="text" id="CV" ></input>
                </div>
                <div>
                    <h3>date</h3>
                    <input className="inp-text" type="date" timezone="[[timezone]]" id="date" ></input>
                </div>
            </div>
            <div className="input-text-col">
                <div>
                    <h3>Noleggio</h3>
                    <div class="checkbox-wrapper-26 ">
                        <input type="checkbox" id="_checkbox-26" />
                        <label for="_checkbox-26">
                            <div class="tick_mark"></div>
                        </label>
                    </div>
                </div>
                <div>
                    <h3>Offerta</h3>
                    <div class="checkbox-wrapper-26 ">
                        <input type="checkbox" id="_checkbox-26_off" />
                        <label for="_checkbox-26_off">
                            <div class="tick_mark"></div>
                        </label>
                    </div>
                </div>
            </div>
            <h3>Description</h3>
            <textarea className="inp-text" id="description" ></textarea>

            <hr></hr>
            <div id='footer-dialog'>
                <button className="btn-black" onClick={closeDialog}>CLOSE</button>
                <button className="btn-black" onClick={sendData}>SAVE</button>
                <button className="btn-orange" onClick={deleteItem}>DELETE</button>
            </div>
            <Dialogimg i1={i1} i2={i2} ></Dialogimg>
        </div >
    );
}


export default Dialogadm;
