import React, { useState } from 'react';
// import Caroselloinfo from './CaroselloInfo';
import '../App.css';

const qs = require('qs');

function Adminimp() {

  // const [i1, setI1] = useState('');
  // const [i2, setI2] = useState('');
  let [filesDataURL] = useState([]);

  const openUploader = () => {
    const realUploader = document.getElementById("real-uploader");
    realUploader.click();
  }
  const sendData = async (e) => {

    e.preventDefault();

    let oBody = {};
    oBody.model = document.getElementById("input-model").value;
    oBody.CV = document.getElementById("input-CV").value;
    oBody.fuel = document.getElementById("input-fuel").value;
    oBody.KM = document.getElementById("input-KM").value;
    oBody.description = document.getElementById("input-description").value;
    oBody.type = document.getElementById("input-type").value;
    oBody.price = document.getElementById("input-price").value;
    oBody.date = document.getElementById("input-date").value;
    oBody.immagine = filesDataURL[0] || '';
    oBody.immagine2 = filesDataURL[1] || '';
    oBody.immagine3 = filesDataURL[2] || '';
    oBody.immagine4 = filesDataURL[3] || '';
    oBody.immagine5 = filesDataURL[4] || '';
    oBody.immagine6 = filesDataURL[5] || '';
    oBody.immagine7 = filesDataURL[6] || '';
    oBody.immagine8 = filesDataURL[7] || '';
    oBody.immagine9 = filesDataURL[8] || '';
    oBody.immagine10 = filesDataURL[9] || '';
    oBody.immagine11 = filesDataURL[10] || '';
    oBody.immagine12 = filesDataURL[11] || '';
    oBody.immagine13 = filesDataURL[12] || '';
    oBody.immagine14 = filesDataURL[13] || '';
    oBody.immagine15 = filesDataURL[14] || '';

    const url = "https://backend-gdauto.onrender.com/insert"
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: qs.stringify(oBody)
    })
    const content = await response.json();
    if (content.status) {
      alert("auto inserita");
      window.location.reload(false);
    }
    //const image = content.img;
  }


  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });


  const takeImg = async (e) => {


    const previewContainer = document.getElementById("image-preview");
    const previewImage = previewContainer.querySelector(".image-preview_img");
    const carosello = previewContainer.querySelector(".carosello");
    // const slide = previewContainer.querySelectorAll(".slide");
    // const previewSlideImg = carosello.querySelectorAll("img");
    const previewText = previewContainer.querySelector(".text-color-primary");

    let files = e.target.files;
    let file = files[0];

    debugger
    for (var i = 0; i < files.length; i++) {
      var filecoded = await toBase64(files[i])
      //setfilesDataURL(filesDataURL => filesDataURL.concat(filecoded));
      filesDataURL[i] = filecoded;
    }
    console.log(filesDataURL)
    //for custom uploader
    const customText = document.getElementById("custom-text");
    if (files.length === 1) {
      customText.innerHTML = files[0].name
    } else if (files.length > 1) {
      customText.innerHTML = files.length + " file selezionati";
    } else {
      customText.innerHTML = 'no file selected';
    }
    // fine custom uploader


    if (file) {

      if (filesDataURL.length === 1) {
        previewImage.setAttribute("src", filesDataURL[0])
        previewText.style.display = "none";
        previewImage.style.display = "block";
        carosello.style.display = "none";
      } else {
        // setI1(filesDataURL[0]);
        // setI2(filesDataURL[1]);
        previewText.style.display = "none";
        carosello.style.display = "flex";
        previewImage.style.display = "none";
      }

    }

    // fileDataURL = await toBase64(file)
    // previewText.style.display = "none";
    // previewImage.style.display = "block";
    // previewImage.setAttribute("src", fileDataURL)  


  }

  return (
    <>
      <section id="showcaseAdminImp" >
        <div className="container" >
          <div className="inside-container" >
            <div id="input-text">
              <h1>Insert data of <span className="text-color-primary">Auto</span> to import</h1>


              <input className="inp-text" type="text" id="input-model" placeholder="Model"></input>
              <div className="input-text-col">
                <div>

                  <input className="inp-text" type="text" id="input-price" placeholder="Price"></input>
                </div >
                <div>

                  <input className="inp-text" type="text" id="input-KM" placeholder="KM"></input>
                </div>
              </div>
              <div className="input-text-col">
                <div>

                  <input className="inp-text" type="text" id="input-fuel" placeholder="Fuel"></input>
                </div>
                <div>

                  <input className="inp-text" type="text" id="input-type" placeholder="Type"></input>
                </div>
              </div>
              <div className="input-text-col">
                <div>

                  <input className="inp-text" type="text" id="input-CV" placeholder="CV"></input>
                </div>
                <div>

                  <input className="inp-text" type="date" id="input-date" placeholder="Date"></input>
                </div>
              </div>
              <h3>Description</h3>
              <textarea className="inp-text" id="input-description"></textarea>
            </div>

            <div id="input-file">
              <h1>Select <span className="text-color-primary">Image</span> you want to <span className="text-color-primary">Import</span></h1>
              <input type="file" hidden="hidden" id="real-uploader" multiple="multiple" onChange={(e) => takeImg(e)}></input>
              <div>
                <button onClick={openUploader} id="custom-uploader" className="btn-black">UPLOAD</button>
                <span className="text-color-primary" id="custom-text">no file selected</span>
              </div>
              <div id="image-preview">
                {/* <img alt="preview" className="image-preview_img"></img>
                <Caroselloinfo field={field}></Caroselloinfo> */}
                <h4><span className="text-color-primary">image preview</span></h4>
              </div>

              <button onClick={(e) => sendData(e)} className="btn-black">Import</button>
            </div>
          </div>

        </div>
      </section>
    </>
  );
}

export default Adminimp;
