import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import '../App.css';

const qs = require('qs');

function Register() {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');

  const takeCredentials = async event => {
    event.preventDefault();
    const BODY = qs.stringify({
      "username": `${user}`,
      "password": `${password}`
    })
    const url = "https://backend-gdauto.onrender.com/login/register"
    debugger
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: BODY
    })
    debugger
    const content = await response.json();
    content.status ? alert('registrazione avvenuta con successo') : alert('utente già esistente')
    setUser('');
    setPassword('');
    console.log(content.status)


  }

  const changeUser = (e) => {
    setUser(e.target.value);
  }

  const changePass = (e) => {
    setPassword(e.target.value);
  }

  return (
    <>
      <header>
        <nav id="Navbar">
          <div className="container">
            <h1 className="Logo"><span className="text-color-primary">LOGO</span></h1>
          </div>
        </nav>
      </header>
      <section id="showcase">
        <div className="container">
          <h1>if you want to <span className="text-color-primary">Sign in</span></h1>
          <form id="Register" onSubmit={(e) => takeCredentials(e)}>
            <input type="text" placeholder="username" value={user} onChange={changeUser}></input>
            <input type="password" placeholder="password" value={password} onChange={changePass}></input>
            <button>Sign in</button>
          </form>
          <Link className="Link" to="/Login"><li>Login</li></Link>
        </div>
      </section>
    </>
  );
}

export default Register;
