import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import '../App.css';
import Caroselloinfo from './CaroselloInfo';
const qs = require('qs');

function Infopage() {
    // const [i1, setI1] = useState('');
    // const [i2, setI2] = useState('');
    // const [images, setImages] = useState([]);
    const [Field, setField] = useState({});

    // const goToInfo = () => {
    //     const hostname = "http://" + window.location.hostname + ":3000/Info";
    //     window.location.href = hostname;
    // }

    // const goToAbout = () => {
    //     const hostname = "http://" + window.location.hostname + ":3000/About";
    //     window.location.href = hostname;
    // }
    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const AutoId = urlParams.get('autoID');

        async function fetchData() {
            try {
                const BODY = qs.stringify({ ID: AutoId })
                const url = "https://backend-gdauto.onrender.com/read/autoID"
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: BODY
                })
                const content = await response.json();
                const field = content.field[0];
                // setI1(field.immagine);
                // setI2(field.immagine2);
                setField(field);
            } catch (error) {
                console.log(error)
            }

        }
        fetchData();
    }, []);

    return (
        <>
            <Navbar current="About"></Navbar>
            <section id="showcaseInfoPage">
                <div className="container">
                    <div className="inside-container" >
                        <h1><span className="text-color-primary">MODELLO</span> : {Field.nome}</h1>
                        <div className="div-carosello">
                            <div>
                                <Caroselloinfo field={Field}></Caroselloinfo>

                                <div id="div-desc-descrizione">
                                    <div>
                                        <h1><span className="text-color-primary">INFO</span> VEICOLO</h1>
                                        <textarea readOnly id="txt-area-descrizione" value={Field.descrizione}></textarea>
                                    </div>
                                    <hr></hr>
                                    <br></br>
                                    <div>
                                        <h1><span className="text-color-primary">EQUIPAGGIAMENTO</span></h1>
                                        <textarea readOnly id="txt-area-descrizione" value={Field.descrizione}></textarea>
                                    </div>
                                </div>
                            </div>

                            <div id="div-desc-carosello">
                                <ul>
                                    <li><i className="fas fa-gas-pump fa-2x"></i><h3>{Field.fuel}</h3></li>
                                    <li><i className="fas fa-bolt fa-2x"></i><h3>{Field.CV} CV</h3></li>
                                    <li><i className="fab fa-cloudscale fa-2x"></i><h3>{Field.KM} KM</h3></li>
                                    <li><i className="fas fa-car fa-2x"></i><h3>{Field.type}</h3></li>
                                    <li><i className="fas fa-euro-sign fa-2x"></i><h3>{Field.price}</h3></li>
                                    <li><i className="fas fa-calendar-alt fa-2x"></i><h3>{Field.date}</h3></li>
                                </ul>

                                <span id="contattaci-btn" className="btn-orange"><Link to={`/Info`}>contattaci</Link></span>
                                <span id="indietro-btn" className="btn-orange"><Link to={`/About`}><i className="fas fa-arrow-left fa-1x"></i> indietro</Link></span>
                            </div>
                        </div>
                        <br></br>
                        <hr></hr>
                        <br></br>
                        {/* <div id="div-desc-descrizione">
                            <div>
                                <h1><span className="text-color-primary">INFO</span> VEICOLO</h1>
                                <textarea readOnly id="txt-area-descrizione" value={Field.descrizione}></textarea>
                            </div>
                            <hr></hr>
                            <br></br>
                            <div>
                                <h1><span className="text-color-primary">EQUIPAGGIAMENTO</span></h1>
                                <textarea readOnly id="txt-area-descrizione" value={Field.descrizione}></textarea>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Infopage;
