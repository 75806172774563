import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
// import SearchBar from './SearchBar';
import CaroselloNoleggio from './CaroselloNoleggio';
import '../App.css';

function Noleggio() {

  useEffect(() => {
    async function fetchData() {

      try {
        const url = "https://backend-gdauto.onrender.com/read"
        const response = await fetch(url, {
          method: 'GET'
        })
        const content = await response.json();
        const elements = content.field;
        console.log(elements);
        setFields(elements);
      } catch (error) {
        console.log(error)
      }

    }

    fetchData();
  }, []);

  // const [fieldsTemp, setFieldsTemp] = useState([]);
  const [fields, setFields] = useState([{}]);

  // if (fields.length) {


  return (
    <>
      <Navbar current="Noleggio"></Navbar>
      <section id="showcaseNoleggio">
        <div className="container">
          <h1 id='title-noleggio'> <span className="text-color-primary">NOLEGGIO</span> A LUNGO TERMINE</h1>
          <h2>  Paghi un canone fisso, guidi l'auto dei tuoi sogni e benefici del servizi necessari alla tua mobilità<br></br></h2>
          <h3>  Il Noleggio a Lungo Termine semplifica la
            gestione della tua vettura, liberandoti
            dall'obbligo di acquisto, gestione e
            rivendita.

            A fronte del pagamento di un canone
            mensile fisso, potrai guidare vetture delle
            più note Case Automobilistiche usufruendo
            di tutti i servizi necessari e concentrarti sul
            tuo core business, liberando risorse
            finanziarie e umane e beneficiando di tutti i
            vantaggi del Noleggio.<br></br></h3>
          {/* {fields.map((field, index) => (
            <Listelement element={field} key={index}></Listelement>
          ))} */}
          <CaroselloNoleggio data={fields}></CaroselloNoleggio>
          <h1 id='title-noleggio'>RICHIEDI <span className="text-color-primary">PREVENTIVO</span></h1>
          <div id="form-noleggio">
            <input className="inp-text" placeholder='Nome *' type="text" def  ></input>
            <input className="inp-text" placeholder='Cognome *' type="text" def  ></input>
            <input className="inp-text" placeholder='Codice Fiscale *' type="text" def  ></input>
            <input className="inp-text" placeholder='Email *' type="text" def  ></input>
            <select className="inp-text" placeholder='A quale veicolo sei interesato? *' type="text" def  >
              <option value="" disabled selected>A quale veicolo sei interesato? *</option>
            </select>
          </div>
        </div>
      </section>
    </>
  );
  // }
}

export default Noleggio;
