import React, { useState } from 'react'
import Imgcomp from "./imgComp";

const Carosello = (props) => {

    let carouselArray = [<Imgcomp src={props.i1}></Imgcomp>];
    const [x,setX] = useState (0)

    // window.setInterval(function(){
    //     goLeft();
    //   }, 7000);

    const goLeft = () => {
        x === 0 ? setX(-100*(carouselArray.length-1)) : setX(x + 100);
     };
    const goRight = () => {
        x === -100*(carouselArray.length-1) ? setX( 0 ) : setX(x - 100);
     };
    return (
        <div className="carosello">
            {
                carouselArray.map((item, index) => {
                    return (
                        <div className="slide" key={index} style={{transform:`translateX(${x}%)`}}>
                            {item}
                        </div>
                    )
                })
            }
            <button id="goLeft" onClick={goLeft}><i className="fas fa-chevron-left fa-3x"></i></button>
            <button id="goRight" onClick={goRight}><i className="fas fa-chevron-right fa-3x"></i></button>
        </div>
    )

}

export default Carosello;