import React, { useState } from 'react';
import Navbar from './Navbar';
// import Caroselloinfo from './CaroselloInfo';
import '../App.css';
const qs = require('qs');


function Sell() {


    // const [i1, setI1] = useState('');
    // const [i2, setI2] = useState('');
    let [filesDataURL] = useState ([]);
    const openUploader = () => {
        const realUploader = document.getElementById("real-email-uploader");
        realUploader.click();
    }
    const sendData = async (e) => {
        e.preventDefault();
        const model = document.getElementById("input-email-model").value;
        const fuel = document.getElementById("input-email-fuel").value;
        const CV = document.getElementById("input-email-CV").value;
        const KM = document.getElementById("input-email-KM").value;
        const date = document.getElementById("input-email-date").value;
        const email = document.getElementById("input-email-email").value;
        const description = document.getElementById("input-email-description").value;
        console.log(filesDataURL)
        const text = `Modello: ${model}\nCarburante: ${fuel}\nPotenza: ${CV}\nKM: ${KM}\nAnno: ${date}\nNote:\n${description}`
        const url = "https://backend-gdauto.onrender.com/sendmail/valutazione";
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: qs.stringify({ "Email": email, "Text": text, "Attachement1": filesDataURL[0], "Attachement2": filesDataURL[1] })
        })
        const content = await response.json();
        if (content.status) {
            alert("Email sended")
        } else {
            alert("Error Occurs")
        }
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });


    const takeImg = async (e) => {


        // const previewContainer = document.getElementById("image-email-preview");
        // const previewImage = previewContainer.querySelector(".image-preview_img");
        // const carosello = previewContainer.querySelector(".carosello");
        // const slide = previewContainer.querySelectorAll(".slide");
        // const previewSlideImg = carosello.querySelectorAll("img");
        // const previewText = previewContainer.querySelector(".text-color-primary");

        let files = e.target.files;
        // let file = files[0];

        for (var i = 0; i < files.length; i++) {
            var filecoded = await toBase64(files[i])
            filesDataURL[i] = filecoded;
        }

        //for custom uploader
        const customText = document.getElementById("custom-email-text");
        if (files.length === 1) {
            customText.innerHTML = files[0].name
        } else if (files.length > 1) {
            customText.innerHTML = files.length + " file selezionati";
        } else {
            customText.innerHTML = 'no file selected';
        }
        // fine custom uploader


        // if (file) {

        //     if (filesDataURL.length === 1) {
        //         previewImage.setAttribute("src", filesDataURL[0])
        //         previewText.style.display = "none";
        //         previewImage.style.display = "block";
        //         carosello.style.display = "none";
        //     } else {
        //         // setI1(filesDataURL[0]);
        //         // setI2(filesDataURL[1]);
        //         previewText.style.display = "none";
        //         carosello.style.display = "flex";
        //         previewImage.style.display = "none";
        //     }

        // }

    }


    return (
        <>
            <Navbar current="Sell"></Navbar>
            <section id="showcaseSell">
                <div className="container">
                    <div className="inside-container">

                        <div id="input-text">
                            <h1>Inserisci i dati dell' <span className="text-color-primary">Auto</span> che vuoi vendere</h1>


                            <div className="input-text-col">
                                <div>
                                    <input className="inp-text" type="text" id="input-email-model" placeholder="Model"></input>
                                </div>
                                <div>

                                    <input className="inp-text" type="text" id="input-email-KM" placeholder="KM"></input>
                                </div>
                            </div>
                            <div className="input-text-col">
                                <div>

                                    <input className="inp-text" type="text" id="input-email-fuel" placeholder="Fuel"></input>
                                </div>
                                <div>

                                    <input className="inp-text" type="text" id="input-email-type" placeholder="Type"></input>
                                </div>
                            </div>
                            <div className="input-text-col">
                                <div>

                                    <input className="inp-text" type="text" id="input-email-CV" placeholder="CV"></input>
                                </div>
                                <div>
                                    <input className="inp-text" type="date" id="input-email-date" placeholder="Date"></input>
                                </div>
                            </div>
                            <h3>Note da segnalare</h3>
                            <textarea className="inp-text" id="input-email-description"></textarea>
                        </div>

                        <div id="input-file">
                            <h1>Carica una <span className="text-color-primary">Foto</span> del <span className="text-color-primary">Veicolo</span></h1>
                            <input type="file" hidden="hidden" id="real-email-uploader" multiple="multiple" onChange={(e) => takeImg(e)}></input>
                            <div>
                                <button onClick={openUploader} id="custom-email-uploader" className="btn-black">UPLOAD</button>
                                <span className="text-color-primary" id="custom-email-text">no file selected</span>
                            </div>
                            {/* <div id="image-email-preview">
                                <img src="" alt="preview" className="image-preview_img"></img>
                                <Caroselloinfo i1={i1} i2={i2}></Caroselloinfo>
                                <h4><span className="text-color-primary">image preview</span></h4>
                            </div> */}
                            <div>
                                <input placeholder='eMail' className="inp-text" type="text" id="input-email-email"></input>
                            </div>
                            <button onClick={(e) => sendData(e)} className="btn-black">invia mail</button>
                        </div>
                    </div>

                </div>

            </section>



        </>
    );
}

export default Sell;
