import React from 'react';
import Navbar from './Navbar';
import Carosello from './Carosello';
import CaroselloAutoList from './CaroselloAutoList';
import i3 from '../img-carosello/gdauto_logo.png'
import IframeResizer from 'iframe-resizer-react'
import '../App.css';


function Home() {

  // useEffect(() => {
  //   async function fetchData() {

  //     try {
  //       const url = "https://backend-gdauto.onrender.com/read"
  //       debugger
  //       const response = await fetch(url, {
  //         method: 'GET'
  //       })
  //       const content = await response.json();
  //       const elements = content.field;
  //       console.log(elements);
  //       setFields(elements);
  //     } catch (error) {
  //       console.log(error);
  //       setFields([{ "modello": "ford" }, { "modello": "ford" }, { "modello": "ford" }]);
  //     }
  //   }

  //   fetchData();
  // }, []);

  // const [fields, setFields] = useState([{}])

  return (
    <>
      <Navbar current='Home'></Navbar>

      <section id="showcaseHome">
        <div className="container">
          {/* <h1>-Vendita vetture usate <br /><br />
            - Permute<br /><br />
            -Contovendita <br /><br />
            -Garanzia 12 mesi <br /><br />
            -Finanziamenti <br /><br />
            -Noleggio a lungo termine</h1> */}
          <Carosello i1={i3}></Carosello>
        </div>
      </section>
      <section id="showcase2">
        {/* <div id="title">
          <h1><span className="text-color-primary">SERVIZI</span> OFFERTI</h1>
        </div> */}

        <div className="container">
          <div className="box1">
            <div className="card">
              <div className="img-container">
                {/* <i className="fas fa-tools fa-5x"></i> */}
                <h2>SERVIZI</h2>
              </div>
              <div className="info-container">
                <h2>    - OFFICINA     </h2>
                <br></br>
                <h2>    - GOMMISTA     </h2>
                <br></br>
                <h2>    - CARROZZIERE     </h2>
                <br></br>
                <h2>    - REVISIONI     </h2>
                <br></br>
                <h2>    - FINANZIAMENTI     </h2>
                <br></br>
                <h2>    - ASSICURAZIONI     </h2>
                <br></br>
                <h2>    - VENDI LA TUA AUTO !     </h2>
              </div>
            </div>
          </div>


          <div className="box2">
            <div className="card">
              <div className="img-container">
                {/* <i className="fas fa-user-friends fa-5x"></i> */}
                <h2>CHI SIAMO</h2>
              </div>
              <div className="info-container">
                <p>
                  GD AUTO è un’azienda attiva da 10 anni
                  Che tu debba vendere la tua auto o che tu voglia acquistarne una usata, nel nostro salone a Tavazzano troverai persone competenti e cordiali, pronte a consigliarti. 
                  <br></br>
                  Perché l'obiettivo di tutto il Team è uno solo: la tua soddisfazione.
                  Pensiamo a tutto noi, anche al passaggio di proprietà presso le agenzie autorizzate ACI.
                  Se avrai bisogno di un finanziamento saremo pronti ad offrirti quello più adatto a te.
                  <br></br>
                  Permutiamo la tua vecchia auto al prezzo a te più favorevole.
                  E se invece devi solo vendere la tua auto, potrai venire da noi. Ti faremo subito la nostra miglior valutazione e svolgeremo noi tutte le pratiche per il passaggio di proprietà.
                </p>
              </div>
            </div>
          </div>


          {/* <div className="box3">
            <div className="card">
              <div className="img-container">
                 <i className="fas fa-hand-holding-usd fa-5x"></i> 
                <h2>NOLEGGIO</h2>
              </div>
              <div className="info-container">
                <p>Keypress will return a character connected to a key ( in correct caps etc ), Keyup will return the number of hardware button pressed. For ESC you'd want the hardware code 27 ( not the ascii character 27 ) – Joeri Sep 30 '15 at 19:40</p>
              </div>
            </div>
          </div> */}



        </div>
      </section>
      <section id="showcase-caros-sell">
        <h1><span className="text-color-primary">BEST</span> PRICE</h1>
        <CaroselloAutoList ></CaroselloAutoList>
      </section>
      <section id="showcase-review">
        <h1>COSA <span className="text-color-primary">PENSANO </span>DI NOI</h1>

      </section>

      <IframeResizer id='testimonialto-carousel-all-testimonial-google-space-tag-all-light' src="https://embed-v2.testimonial.to/carousel/all/testimonial-google-space?theme=light&autoplay=off&showmore=off&one-row=on&same-height=off&tag=all&arrowColor=9BA9B4" style={{ backgroundColor: "#e7e5e1d0", border: "none" }} />

    </>
  );
}

export default Home;
