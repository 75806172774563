import React from 'react';
import Home from './components/Home'
import Info from './components/Info'
import About from './components/About'
import Login from './components/Login'
import Infopage from './components/InfoPage'
// import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.css';
import Register from './components/Register';
import Admin from './components/Admin';
import Sell from './components/Sell';
import Noleggio from './components/Noleggio';

function App() {
  return (

    <div id="mastercase">

      {/* <head>
        <link rel="stylesheet" media="screen and (max-width:500px)" href="../src/mobile.css" />
      </head> */}
      <Router>
        {/* <Navbar current='Home'></Navbar> */}
        <div id='mastercase-content'>
          <Switch>
            <Route exact path="/Login" component={Login}></Route>
            <Route exact path="/Info" component={Info}></Route>
            <Route exact path="/About" component={About}></Route>
            <Route exact path="/" component={Home}></Route>
            <Route exact path="/Register" component={Register}></Route>
            <Route exact path="/Admin" component={Admin}></Route>
            <Route exact path="/Sell" component={Sell}></Route>
            <Route exact path="/Noleggio" component={Noleggio}></Route>
            <Route path="/InfoPage" component={Infopage}></Route>
          </Switch>
        </div>
        <footer id="Footer">© 2020  Group Italia S.p.A.</footer>
      </Router>
    </div>

  );
}

export default App;
