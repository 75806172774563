import React from 'react';
import Navbar from './Navbar';
import '../App.css';
const qs = require('qs');

function Info() {

  const sendMail = async () => {
    const subject = document.getElementById("subject").value;
    const Email = document.getElementById("Email").value;
    const Text = document.getElementById("Text").value;
    const url = "http://localhost:4000/sendmail";
    // const url = "https://backend-gdauto.onrender.com/sendmail";
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: qs.stringify({ "subject": subject, "Email": Email, "Text": Text })
    })
    const content = await response.json();
    if (content.status) {
      alert("Email sended")
      window.location.reload(false);
    } else {
      alert("Error Occurs")
      console.log(content.status)
    }
    console.log(content);
  }

  return (
    <>
      <Navbar current="Info"></Navbar>
      <section id="showcaseInfo">
        <div className="container">
          <div className="div-map">
            <h1>le nostre sedi</h1>
            <iframe title='iframe-gmaps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2805.076068032732!2d9.409079276058835!3d45.327086371071985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786d2d612348c9d%3A0x21e4e01f9f96ab64!2sGD%20Auto!5e0!3m2!1sen!2sit!4v1694117479915!5m2!1sen!2sit"></iframe>
            {/* <iframe title='iframe-gmaps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2805.076068032732!2d9.409079276058835!3d45.327086371071985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786d2d612348c9d%3A0x21e4e01f9f96ab64!2sGD%20Auto!5e0!3m2!1sen!2sit!4v1694117479915!5m2!1sen!2sit"></iframe> */}
            {/* <iframe title='iframe-gmaps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2794.781794426144!2d9.252291015768513!3d45.534596436764026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786b87deb1d24ad%3A0xd230585ad02a2817!2sVia%20Marzabotto%2C%20229%2C%2020099%20Sesto%20San%20Giovanni%20MI!5e0!3m2!1sit!2sit!4v1598530227409!5m2!1sit!2sit"></iframe> */}
          </div>
          <div className="inside-container">
            <div className="div-email">
              <h1>CONTACT <span className="text-color-primary">US</span></h1>
              <h3>subject</h3>
              <input id="subject" type="text"></input>
              <h3>your <span className="text-color-primary">Email</span></h3>
              <input id="Email" type="text"></input>
              <h3>Text</h3>
              <textarea id="Text"></textarea>
              <button onClick={sendMail} className="btn-black">Send</button>
            </div>
          </div>
        </div>
      </section>
      {/* <hr id="hr-social"></hr> */}
      <section id="section-social">
        <div id="title">
          <h1 id="social">SOCIAL</h1>
        </div>
        <div className="container">
          <div className="img-social" id="instagram">
            <a rel='noreferrer' target="_blank" href="https://www.instagram.com/?hl=it"><i className="fab fa-instagram fa-4x"></i></a>
          </div>
          <div className="img-social" id="facebook">

            <a rel='noreferrer' target="_blank" href="https://it-it.facebook.com/"><i className="fab fa-facebook-f fa-4x"></i></a>
          </div>
          <div className="img-social" id="watsapp">
            <a rel='noreferrer' target="_blank" href="https://www.whatsapp.com/?lang=it"><i className="fab fa-whatsapp fa-4x"></i></a>
          </div>
        </div>
      </section>
    </>
  );
}

export default Info;
